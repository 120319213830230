<template>
  <div class="content">
    <div class="container">
      <div class="top-bg">
        <p>{{ $t('joinUs.title') }}</p>
        <h2>{{ $t('joinUs.subTitle') }}</h2>
      </div>
      <div class="body-div">
        <div class="title">
          <p>{{ $t('joinUs.descTitle') }}</p>
          <div class="line" />
        </div>
        <div class="desc">
          <p>{{ $t('joinUs.descSubTitle') }}<br>{{ $t('joinUs.content1') }}<br>{{ $t('joinUs.content2') }}</p>
        </div>
      </div>
      <div class="section-div">
        <p class="sectionTitle">{{ $t('joinUs.section1Title') }}</p>
        <ul>
          <li
            v-for="(item) in section1"
            :key="item.index"
          >
            {{ `${item.index}、${item.title}` }}
          </li>
        </ul>
      </div>
      <div class="section-div section-div2">
        <p class="sectionTitle">{{ $t('joinUs.section2Title') }}</p>
        <ul>
          <li
            v-for="(item) in section2"
            :key="item.index"
          >
            {{ `${item.index}、${item.title}` }}
          </li>
        </ul>
      </div>
      <p class="bottom-text">{{ $t('joinUs.vitae') }}<span>{{ $t('joinUs.vitaeGray') }}</span><span>{{ $t('joinUs.vitaeOrange') }}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      section1: new Array(10).fill(0).map((item, index) => {
        return { index: Number(index) + 1, title: this.$t(`joinUs.section1Desc${Number(index) + 1}`) }
      }),
      section2: new Array(5).fill(0).map((item, index) => {
        return { index: Number(index) + 1, title: this.$t(`joinUs.section2Desc${Number(index) + 1}`) }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-text {
  margin-top: 57px;
  font-size: 16px;
  color: #333;
  span:nth-child(1) {
    color: #666;
  }
  span:last-child {
    color: #FF6803;
  }
}
.desc {
  font-size: 16px;
  color: #666;
  line-height: 28px;
  margin-top: 20px;
}
.top-bg {
  background: url("~@/assets/images/loanImg/join-top.png") no-repeat;
  background-size: 100% 100%;
  width: 1200px;
  padding: 39px 26px;
  p {
    font-size: 16px;
    color: #333;
  }
  h2 {
    color: #333333;
    font-size: 36px;
    font-weight: bold;
    margin-top: 28px;
  }
}
li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: #666666;
  font-size: 16px;
}
.section-div {
  .sectionTitle {
    font-size: 16px;
    color: #333333;
    margin-bottom: 19px;
    margin-top: 36px;
  }
}
.section-div2 {
  margin-top: 51px;
}
.title{
  margin-top: 26px;
  font-size: 16px;
  color: #333333;
  .line {
    width: 19px;
    height: 2px;
    background-color: #FF6803;
    margin-top: 6px;
  }
}
.content{
  padding-top: 100px;
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 90px;
}
</style>
